import { initializeApp } from "firebase/app";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";

import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  connectFirestoreEmulator,
} from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyByLNtd8YdyW8n7sUi3LYm8d-Gbm712su8",
  authDomain: "gift-suggestor-b53f6.firebaseapp.com",
  projectId: "gift-suggestor-b53f6",
  storageBucket: "gift-suggestor-b53f6.appspot.com",
  messagingSenderId: "99972355916",
  appId: "1:99972355916:web:ee431ef5cb403b8bb361e9",
  measurementId: "G-N34BX7HG5K",
};

const app = initializeApp(firebaseConfig);

const db = getFirestore();

//login things
const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({
  login_hint: "user@example.com",
});

const auth = getAuth(app);

// if (location.hostname === 'localhost') {
//   connectFirestoreEmulator(db, 'localhost', 8080);
// }

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const userQuery = query(
      collection(db, "users"),
      where("uid", "==", user.uid)
    );
    const docs = await getDocs(userQuery);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const logout = () => {
  signOut(auth);
};
export {
  app,
  auth,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};
