import Menu from "./comps/menu";
import Footer from "./comps/footer";
import GetInfo from "./comps/get_info";
import Loading from "./comps/loading";
import Suggestions from "./comps/suggestions";

import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./comps/about";
import BlogHome from "./comps/blog_comps/blog_home";
import NotFound from "./comps/notfound";
import Login from "./comps/login_comps/login";
import Logout from "./comps/login_comps/logout";

import Create from "./comps/blog_comps/create_post_test";
import BlogDetails from "./comps/blog_comps/blog_details";
import Register from "./comps/login_comps/register";
import BlogPost from "./comps/blog_comps/blog_post";

function App() {
  return (
    <div className="App">
      <Menu />
      
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<GetInfo />} />
          <Route path="/suggestions/:suggestionID" element={<Suggestions />} />
          <Route path="/about" element={<About />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/giftideas" element={<BlogHome />} />
          <Route path="/notfound" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/giftideas/create" element={<Create />} />
          <Route path="/giftideas/:id" element={<BlogPost />} />
          <Route path="/register" element={<Register />} />
        </Routes>
      </BrowserRouter>

      <Footer />
    </div>
  );
}

export default App;
