import { doc, addDoc, collection } from "firebase/firestore";
import { useState } from "react";
import uuid from "react-uuid";
import { db, auth } from "../firebase-config";
import LoadingScreen from "./loading";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import Suggestions from "./suggestions";



function GetCurrentYear() {
  let today = new Date();
  return today.getFullYear();
}

function AddYear(date) {
  let year = GetCurrentYear() + 1;
  return new Date(year, date.getMonth(), date.getDate());
}



function GetNextOccurence(dateIn, today) {
  if (today.getFullYear() == dateIn.getFullYear()) {
    //guard to make sure we are dealing with same years
    if (today.getMonth() < dateIn.getMonth()) {
      return dateIn;
    } else if (today.getMonth() == dateIn.getMonth()) {
      if (today.getDate() <= dateIn.getDate()) {
        return dateIn;
      } else {
        return AddYear(dateIn);
      }
    } else {
      return AddYear(dateIn);
    }
  }
}



const GetUserId = (user) => {
  if (user) {
    console.log(user);
    return user.uid;
  } else {
    // implement cookie authentication
    return uuid();
  }
};
function GetInfo() {
  const [user, loading, error] = useAuthState(auth);
  const [age, setage] = useState("");
  const [interests, setinterests] = useState("");
  const [budget, setbudget] = useState("75");
  const [name, setName] = useState("");
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false)


  const HandleCLick = async (event) => {
    setIsLoading(true)
    event.preventDefault()
    
    const requestBody = {
      user_id: GetUserId(user),
      age: age,
      interests: interests,
      budget: budget,
      name: name,
      dateRequested: Date.now(),
    }
    const requestDetails = {
      method: 'POST', 
      body: JSON.stringify(requestBody), 
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    }

      await fetch(' https://us-central1-gift-suggestor-b53f6.cloudfunctions.net/suggestionRequest', requestDetails)
     .then((response) => {
      return response.json()
     })
     .then((data) => {
      console.log(data)
      setData(data);
      setIsLoading(false)
      setHasLoaded(true)
      
     })
     
      
  }

  if (isLoading && !hasLoaded) {
    return (
      <LoadingScreen className="bg-slate-300 font-bold text-xl min-h-screen items-center fixed top-20 text-center w-screen" />
      )
  }
  else if (hasLoaded) {
   // console.log(data)
    return (
      <Suggestions data={data}/>
    )
  }
  else {
  return (
    <div className="page bg-slate-300 min-h-screen">
      <div className="flex justify-center align-vertical">
        <form className="grid grid-cols mx-auto w-full max-w-4xl bg-cyan-700 rounded-md py-3 mt-48">
          <div className="justify-items-center content-center p-5 col-start-1 col-end-4">
          <div className="md:flex md:items-center mb-6">
            <div className="md:w-1/3">
              <label
                className="block text-white font-bold md:text-right mb-1 md:mb-0 pr-4"
                data-bs-toggle="tooltip" data-bs-placement="left" title="Just first name is fine"
                htmlFor="inline-password"
              >
                Name of Recipient
              </label>
            </div>
            <div className="md:w-2/3">
              <input
                className="bg-gray-200 appearance-none border-2 
														border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight 
														focus:outline-none focus:bg-white focus:border-blue-500"
                id="inline-password"
                type="text"
                placeholder="ex: John Doe"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
          <div className="md:flex md:items-center mb-6">
            <div className="md:w-1/3">
              <label
                className="block text-white font-bold md:text-right mb-1 md:mb-0 pr-4"
                data-bs-toggle="tooltip" data-bs-placement="left" title="How old is your recipient?"
                htmlFor="inline-full-name"
              >
                Age of Recepient
              </label>
            </div>
            <div className="w-28">
              <input
                className="bg-gray-200 appearance-none border-2 
														border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight 
														focus:outline-none focus:bg-white focus:border-blue-500"
                id="inline-full-name"
                type="number"
                placeholder="ex: 23"
                value={age}
                onChange={(e) => setage(e.target.value)}
              />
            </div>
          </div>
          <div className="md:flex md:items-center mb-6">
            <div className="md:w-1/3">
              <label
                className="block text-white font-bold md:text-right 
													mb-1 md:mb-0 pr-4" 
                data-bs-toggle="tooltip" data-bs-placement="left" title="For optimal recomendations, use 3 unique interests separated by commas"
                htmlFor="inline-full-name"
              >
                Interests
              </label>
            </div>
            <div className="md:w-2/3">
              <input 
                className="bg-gray-200 appearance-none border-2 
														border-gray-200 rounded w-full py-2 px-4 text-gray-700 
														leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                id="inline-interests"
                type="text"
                placeholder="ex: Disney, exercise, crypto"
                value={interests}
                onChange={(e) => setinterests(e.target.value)}
              />
            </div>
          </div>
          <div className="md:flex md:items-center mb-6">
            <div className="md:w-1/3">
              <label
                className="block text-white font-bold md:text-right 
													mb-1 md:mb-0 pr-4"
                data-bs-toggle="tooltip" data-bs-placement="left" title="How much are you willing to spend?"
                htmlFor="inline-full-name"
              >
                Gift Budget
              </label>
            </div>
            <div className="w-60 flex">
              <span
                className="flex items-center leading-normal bg-grey-lighter border-1 rounded-r-none border border-r-0
												border-gray-300 px-0 whitespace-no-wrap text-grey-dark text-md w-8 h-10 bg-gray-300 justify-center
												rounded text-gray-600"
              >
                <p>$</p>
              </span>
              <input
                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 pl-3
													text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500
													w-1/3 rounded-l-none"
                id="inline-full-name"
                type="number"
                min="5"
                step="1"
                max="1000"
                placeholder="75"
                value={budget}
                onChange={(e) => setbudget(e.target.value)}
              />
            </div>
            </div>
          </div>
          <div className="flex items-center justify-center col-start-1 col-end-4">
            <div className="">
              <button
                onClick={HandleCLick}
                className="shadow bg-black text-white focus:shadow-outline focus:outline-none
										font-bold py-2 px-4 rounded w-full disabled:bg-slate-500 disabled:text-slate-700 mx-auto"
                type="submit"
                disabled={!interests}
              >
                Make my gift list!
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}}

export default GetInfo;
