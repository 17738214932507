import {useEffect, useRef, useState, Fragment } from 'react';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header'; 
import Underline from '@editorjs/underline';
import List from '@editorjs/list';
import SimpleImage from '@editorjs/simple-image';

const DEFAULT_INITIAL_DATA = () => {
  return {
    "time": new Date().getTime(),
    "blocks": [
      
    ]
  }
}

const EDITTOR_HOLDER_ID = 'editorjs';

const Editor = (props) => {
  const ejInstance = useRef();
  const [editorData, setEditorData] = useState(DEFAULT_INITIAL_DATA);

  // This will run only once
  useEffect(() => {
    if (!ejInstance.current) {
      initEditor();
    }
    return () => {
      ejInstance.current.destroy();
      ejInstance.current = null;
    }
  }, []);

  const initEditor = () => {
    const editor = new EditorJS({
      holder: EDITTOR_HOLDER_ID,
      logLevel: "ERROR",
      data: editorData,
      onReady: () => {
        ejInstance.current = editor;
      },
      onChange: async () => {
				const data = await editor.save();
        props.setTheSave(data);
      },
      autofocus: true,
      tools: { 
        header: Header, 
				image: SimpleImage,
				underline: Underline,
				list: {
					class: List,
					inlineToolbar: true,
					config: {
						defaultStyle: 'unordered'
					}
				},
      }, 
    });
  };

  return (
    <Fragment>
      <div id={EDITTOR_HOLDER_ID}> </div>
    </Fragment>
  );
}

export default Editor;