import { useState } from "react";

function LoadingScreen() {
  return (
    <div>
      <div className="footer-spacer w-screen h-screen"></div>
      <div className="fixed w-screen h-screen block top-0 left-0 text-center z-40">
        <div className="bg-gray-900/80">
          <div className="py-20"></div>
          <div className="flex flex-wrap justify-center">
            <img
              src="https://media2.giphy.com/media/MXJV9ONtFHG46lZS0G/giphy.gif"
              alt="Your gift list is being prepared"
              className="rounded-lg max-w-xs"
            />
          </div>
          <p className="text-5xl m-5 animate-spin">🎁</p>
          <span>
            <h1 className="text-lg bg-blue-300 p-4 mt-5">
              Your gift list is being prepared...
            </h1>
          </span>
          <div className="h-screen"></div>
        </div>
      </div>
    </div>
  );
}

export default LoadingScreen;
