import { useAuthState } from "react-firebase-hooks/auth";
import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { auth, db } from "../firebase-config";
import { Link, useNavigate } from "react-router-dom";
import { LogInButton, LogOutButton } from "../comps/login_comps/buttons";
import logo2 from "../images/logo_2.png";
import {ReactComponent as TikTok} from "./../images/tiktok-logo.svg";
import {ReactComponent as Instagram} from "./../images/instagram-logo.svg";

function Menu() {
  const [user, loading, error] = useAuthState(auth);
  const [loggedIn, setLoggedIn] = useState(false);
  const [name, setName] = useState("");
  useEffect(() => {
    let temp = user != null;
    setLoggedIn(temp);
  } );

  const [MenuCollapse, setMenuCollapse] = useState("hidden")

  const toggleMenu = () => {
    if (MenuCollapse === "hidden") {
      setMenuCollapse("")
    } else {
      setMenuCollapse("hidden")
    }
  }
  
  const HandleLogin = () => {
	alert("loggedin")
  }

  return (
 
    <div>
      <nav className="bg-white px-2 sm:px-4 py-2.5 fixed w-full z-20 top-0 left-0 border-b border-gray-200">
        <div className="container flex flex-wrap justify-between items-center mx-auto">
        <a href="/" className="flex items-center">
            <img
              src={logo2}
              className="h-20 rounded "
              alt="Flowbite Logo"
            ></img>
          </a>
        <div className="flex md:order-2" onClick={toggleMenu}>
            <button data-collapse-toggle="navbar-sticky" type="button" className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200" aria-controls="navbar-sticky" aria-expanded="false">
              <span className="sr-only">Open main menu</span>
              <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
          </button>
        </div>
        <div className={MenuCollapse + " justify-between items-center w-full md:flex md:w-auto md:order-1"} id="navbar-sticky">
          <ul className="flex flex-col p-4 text-center mt-4 bg-gray-50 rounded-lg border border-gray-100 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white">
            <li>
              <a href="/" className="block py-2 pr-4 pl-3 text-white bg-cyan-700 rounded md:bg-transparent md:text-cyan-700 md:p-0" aria-current="page">Home</a>
            </li>
            <li>
              <a href="/about" className="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-cyan-700 md:p-0">About</a>
            </li>
            <li>
              <a href="/giftideas" className="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-cyan-700 md:p-0 ">Gift Ideas</a>
            </li>
            <li>
              <a href="https://www.tiktok.com/@thegiftnerds" className="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-cyan-700 md:p-0">
                <div className="flex items-center justify-center outline-black outline rounded-md p-2 text-black flex-row m-auto">
                  <div className="flex">
                    <TikTok className="w-5 fill-black mr-2 m-auto"/> TikTok
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/thegiftnerds/" className="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0">
                <div className="flex items-center justify-center outline-black  rounded-md p-2 text-white bg-gradient-to-bl from-purple-500 to-orange-500">
                  <div className="flex">
                    <Instagram className="w-5 fill-white mr-2 m-auto"/> <p>Instagram</p>
                  </div>
                </div>
              </a>
            </li>
	   <li>
	  	<button className ="block w-full py-2 pr-4 pl-3 text-white bg-cyan-700 rounded md:bg-transparent md:text-cyan-700 md:p-0" onClick={HandleLogin}>
	  		Login
	  	</button>
	   </li>	  
          </ul>
        </div>
        </div>
      </nav>


      {/* <nav className="bg-white px-2 sm:px-4  rounded fixed w-full z-20 top-0 left-0 border-b border-gray-200">
        <div className="container flex flex-wrap justify-between items-center mx-auto">
          <a href="/" className="flex items-center">
            <img
              src={logo2}
              className="h-20 rounded "
              alt="Flowbite Logo"
            ></img>
          </a>
          <div className="space-y-[0.46rem] mr-4">
            <div className="w-8 h-[0.2rem] bg-gray-600"></div>
            <div className="w-8 h-[0.2rem] bg-gray-600"></div>
            <div className="w-8 h-[0.2rem] bg-gray-600"></div>
          </div>
          <div
            className="hidden justify-between items-center w-full md:flex md:w-auto md:order-1"
            id="navbar-sticky"
          >
            <div className="font-mono text-black font-black">
            <ul className="flex flex-col p-4 mt-4 bg-gray-50 rounded-lg border border-gray-100 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white">
              <li className="m-auto">
                <a
                  href="/"
                  className=" mt-3 pr-4 pl-3  text-l rounded order-right-1 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 b"
                >
                  Home
                </a>
              </li>
              <li className="m-auto">
                <a
                  href="/about"
                  className=" py-2 pr-4 pl-3  text-l  rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
                >
                  About
                </a>
              </li>
              <li className="m-auto">
                <a
                  href="/giftideas"
                  className=" py-2 pr-4 pl-3  text-l  rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
                >
                  Gift Ideas
                </a>
              </li>
              <li className="m-auto">
                <a href="https://www.tiktok.com/@thegiftnerds" target="_blank">
                  <span className="bg-black rounded-md p-2 text-white flex flex-row m-auto">
                    <TikTok className="w-5 fill-white mr-2 m-auto"/> TikTok
                  </span>
                </a>
              </li>
              <li className="m-auto">
                <a href="https://www.instagram.com/thegiftnerds/" target="_blank">
                  <span className="bg-black rounded-md p-2 text-white flex flex-row">
                    <Instagram className="w-5 fill-white mr-2 m-auto"/> Instagram
                  </span>
                </a>
              </li>
            </ul>
            </div>
          </div>
        </div>
      </nav> */}
    </div>
  );
}

export default Menu;
