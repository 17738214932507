import React, { useState } from 'react'
import Editor from './Editor'

import { db } from "../../firebase-config";
import { useAuthState } from "react-firebase-hooks/auth";
import { 
	getDocs, 
	doc,
  setDoc,
  collection,
  query,
  where,
  getDoc
} from "firebase/firestore";

import uuid from "react-uuid";





const Create = () => {
	const [save, setSave] = useState({});

	const postToDB = async () => {
		const blog_id = uuid();
		const title = save.blocks[0].data?.text
		const blog = { ...save, blog_id, title };
		setDoc(doc(db, "blogs", blog_id), blog);
	}

	const handleSave = (save_data) => {
		console.log("The following is save data:");
		console.log(save);
		postToDB();
	}

	const setTheSave = (save_data) => {
		setSave(save_data);
	}

	

  return (
		<>
		<div className='border-2 border-slate-600 mt-24'>
			<Editor 
				setTheSave={setTheSave}
			/>
		</div>
			<button className='p-8 bg-emerald-500 rounded-md text-white text-xl font-bold'
							onClick={() => {
								handleSave()
							}}
			>Click Me</button>
		</>
  )
}

export default Create;