import { db, auth } from "../firebase-config";
import React, { useState, useEffect } from "react";

function Suggestions(props) {
  return (
    <div>
      <div className="bg-slate-300 text-center font-bold text-xl min-h-screen items-center top-20 w-screen">
        <h1 className="m-8 pt-20">
          We found the perfect{" "}
          {props.data.suggestionDeets.occasion
            ? props.data.suggestionDeets.occaion
            : ""}{" "}
          gifts for{" "}
          {props.data.suggestionDeets.name
            ? props.data.suggestionDeets.name
            : "you"}
          !
        </h1>
        <div className=" p-5 m-10  ">
          {props.data.amazonData &&
            props.data.amazonData.map((suggestion, idx) => {
              console.log(props.data.suggestions[idx])
              //update, map the items in suggestion.SearchResult

              return (
                <div className="overflow-x-auto m-5 p-5 bg-white rounded-xl  ">
                  <h1>{props.data.suggestions[idx]}</h1>
                <div className="grid grid-cols-3 gap-3  ">
                  
                  {suggestion.SearchResult.Items &&
                    suggestion.SearchResult.Items.map((item) => {
                      return (
                        
                        <a className="p-8 bg-blue-100 rounded-xl" href={item.DetailPageURL}>
                          <h3 className="">{`${item.ItemInfo.Title.DisplayValue}`}</h3>
                          <img
                            src={
                              item.Images.Primary
                                .Medium.URL
                            }
                            alt="picture alt for ${props.data.suggestion"
                            className="rounded-lg px-10 pb-10 justify-items-center "
                          />
                        </a>
                      );
                    })}
                </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default Suggestions;
