

function NotFound() {

  return (
    <div>
      <div className="  bg-slate-100 text-center font-bold text-xl">
        <h1 className="mb-10">
         Ooops, the page you navigated to is not found, try something new!
        </h1>
      </div>
    </div>
  );
}

export default NotFound;
