import { Link } from "react-router-dom";
import { db } from "../../firebase-config";
import { Navigate, useNavigate } from "react-router-dom";

function GetBlogIntro(blog) {
  if (blog.intro) {
    return blog.intro;
  }
  else if (blog.blocks) {
    let introText = "";
    blog.blocks.slice(1).map(block => introText += block.data.text + " ")
    return introText.substring(0, 300) + "...";
  } 
  else if (blog.body) {
    return blog.body.substring(0, 300) + "...";
  }
  else {
    return ""
  }
}

const BlogList = ({ blogs, title }) => {
  console.log(blogs);

  return (
    <div className="blog-list bg-slate-50 min-h-screen">
      <h2 className="text-center text-4xl font-bold p-10 pt-28 align-vertical ">
        {title}
      </h2>

      {blogs &&
        blogs.map((blog) => {
          var blogUrl = "giftideas/" + blog.blog_id;
          return (
            <a href={blogUrl}>
              <div className="px-10 w-auto">
                <div
                  key={blog.id}
                  className=" items-center justify-center grid grid-cols-2 sm:grid-cols-3 md:grid-cols-7 gap-2 mb-10 shadow-md  w-auto rounded-lg bg-white border border-slate-100 "
                >
                  <div>
                    <img
                      src={
                        "https://source.unsplash.com/random/700x700/?" +
                        blog.title
                      }
                      alt="placeholder image"
                      className="rounded-lg m-3"
                    />
                  </div>
                  <h1 className="flex mx-2 font-bold text-lg sm:col-span-2 text-center justify-center">
                    {blog.title}
                  </h1>
                  <div dangerouslySetInnerHTML={{__html: GetBlogIntro(blog)}} className="col-span-4 mx-2 text-l text-slate-400">
                    
                  </div>
                  <div className="col-span-1 "></div>
                </div>
              </div>
            </a>
          );
        })}
    </div>
  );
};
export default BlogList;
