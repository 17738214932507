import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db, auth } from "../../firebase-config";
import { getDoc, getDocs, collection, doc, limit } from "firebase/firestore";



const renderBlock = (block, id) => {
	const TagsForHire = (block) => {
		let block_type = block.type
		if (block_type == "paragraph") {
			return "p"
		}
		if (block_type == "header") {
			return "h" + block.data.level
		}		
		if (block_type == "list") {
			if (block.data.style == "unordered") {
				return "ul"
			}
			else {
				return "ol"
			}
		}
		else {
			return "h3"
		}
	}

	let CustomTag = TagsForHire(block)

	let FinalBlock;


	if (CustomTag == "ul" || CustomTag == "ol") {
		FinalBlock = (
			<CustomTag key={id} className={CustomTag == "ul" ? "list-disc" : "list-decimal"}>
				{block.data.items.map((item, key) => <li className="my-3" 
																								key={key} dangerouslySetInnerHTML={{__html: item}}></li>)}
			</CustomTag>
		)
	}
	else {
		FinalBlock = (
			<CustomTag key={id} dangerouslySetInnerHTML={{__html: block.data.text}}></CustomTag>
		)
	}

	return (
		<div className="mx-10 my-5">
			{FinalBlock}
		</div>
	)
}




const BlogPost = () => {

	const { id } = useParams();

	const [loading, setLoading] = useState(true);
	const [post, setPost] = useState({blocks: [{data: "Loading"}]});
	
	useEffect(() => {
		
		const get_post_data = async () => {
			const post_data = await getDoc(doc(db, "blogs", id));
			console.log(post_data.data().blocks);
			setPost(post_data.data().blocks);
			
			
			setLoading(false);
			
		}
		
		get_post_data();
	

	
		// return () => {
			
		// }
	}, [])
	

	return (
		<div>
			<img src="https://source.unsplash.com/random/1600x300?gift"
						alt=""
						className="w-full" />
			<div className="post">
			{loading && <div>Loading</div>}
				{!loading && (
					<div>
						{post.map((block, id) => renderBlock(block, id))}
					</div>
				)}

			</div>
		</div>
	);
}

export default BlogPost;