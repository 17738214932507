import matt from "../images/matt_about_small.jpg";
import joe from "../images/joe_about_square.jpeg";

function About() {
  return (
    <div>
      <div className=" pt-28 grid grid-cols-12 bg-slate-100 min-h-screen">
        <h1 className="col-span-12 font-bold text-3xl text-center mb-12">
          Gift Giving. Made EASY.
        </h1>

        <div className="col-start-2 col-end-12 p-5 rounded-xl bg-slate-400 py-10">
          <div className="font-black text-xl py-2"> OUR MISSION </div>
            is to help you find the perfect gift. The best part? You keep the
            reputation as the world's best gift giver
        </div>

        <div className="col-start-2 col-end-12 p-5 rounded-xl bg-white py-10 ">
			<div className = 'grid grid-cols-2 md:grid-cols-5 gap-1 justify-items-center text-center'>
				<div className="col-span-1">
					<img
						src={matt}
						className="rounded-xl  "
						alt="matt_img">
					</img>
					Matt 
				</div>
				<div className="col-span-1">

					<img
						src={joe}
						className="rounded-xl"
						alt="joe_img">
					</img>
					Joe 
				</div>

				<div className="col-span-3 text-right justify-right">

					<div className="font-black text-xl py-2"> OUR TEAM</div>
					was founded with two people- Matt Porter and Joe Poole. Matt and
					Joe met in college, and realized what a great team they could
					make. Although we still are just two members strong, we believe
					that small teams can deliever quality products because we share
					both passion and vision for the product. We are the OG GIFT NERDS.
				</div>
				
			</div> 
        </div>



        <div className="col-start-2 col-end-12 p-5  rounded-xl   bg-slate-400 py-10 mb-10">
          <div className="font-black text-xl py-2"> MACHINE LEARNING </div>
          <div className="">
            is at the heart of what we do. Backed by OpenAI's GPT3 machine
            learning model, our algorithim will find you exactly what you're
            looking for. Sounds confident? We are.
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
