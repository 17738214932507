import BlogList from "./blog_list";
import { useState, useEffect } from "react";
import { db } from "../../firebase-config";

import { getDocs, collection } from "firebase/firestore";
import { Disclosure } from "@headlessui/react";

function BlogHome() {
  const [blogs, setBlogs] = useState([]);

  const fetchBlog = async () => {
    const ref = collection(db, "blogs");
    const blogDocs = await getDocs(ref);

    if (blogDocs) {
      var local_blogs = [];
      blogDocs.forEach((doc) => {
        // console.log(doc.id, "=>", doc.data());
        local_blogs.push(doc.data());
      });
      setBlogs(local_blogs);
    } else {
      console.log("THERE WAS AN ERROR");
    }
  };

  useEffect(() => {
    fetchBlog();
  }, []);

  return (
    <div className="flex grid-cols2">
      <b></b>
      {/* {isPending && <div>Loading...</div>} */}
      <BlogList blogs={blogs} title="Gift Ideas" key={"blog list"} />
    </div>
  );
}
export default BlogHome;
