import {ReactComponent as TikTok} from "./../images/tiktok-logo.svg";
import {ReactComponent as Instagram} from "./../images/instagram-logo.svg";

const Footer = () => {


	return (
	<>
		<div className="footer bg-slate-400 w-screen text-center flex flex-col py-5">
			<div className="">
				<p className="m-auto">© Copyright 2022, The Gift Nerds </p>
			</div>
			<span className="w-auto flex flex-row items-center justify-center mt-3">
				<a href="https://www.tiktok.com/@thegiftnerds" target="_blank">
					<TikTok width="30px" height="30px" className="mx-5 fill-black"/>
				</a>
				<a href="https://www.instagram.com/thegiftnerds/" target="_blank">
					<Instagram fill="black" width="30px" height="30px" className="mx-5"/>
				</a>
			</span>
		</div>
	</>)
}

export default Footer